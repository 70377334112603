import React from "react";
import { Table } from "flowbite-react";
import { motion } from "framer-motion";

function Amc() {
  return (

      <motion.section className="md:px-16 px-3 py-6"
      initial={{ y:-40,opacity:0 }}
      animate={{ y:0, opacity:1}}
      transition={{ ease: "easeIn", duration: 1 }}
      >
        <h1 className="text-3xl underline underline-offset-8 font-bold my-5">About Management Council</h1>
        {/* // nominees management */}
      <div className="overflow-x-auto border border-gray-200 rounded-xl">
        <Table hoverable >
          <Table.Head>
            <Table.HeadCell className="bg-blue-900 text-white text-base md:text-xl">
              NOMINEES OF MANAGEMENT
            </Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y ">
            <Table.Row className="bg-blue-50  ">
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 hover:bg-white/95">
                <h3 className="md:text-lg italic font-bold">Mr. Santosh Pal</h3>
                <p className="italic">
                  President Jayawanti Babu Foundation, Mumbai
                </p>
              </Table.Cell>
            </Table.Row>
            <Table.Row className="bg-blue-50 ">
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 hover:bg-white/95">
                <h3 className="italic md:text-lg  font-bold">Mr. Vinod Kadam</h3>
                <p className="italic">
                  Vice-Chairman, Jayawanti Babu Foundation, Mumbai
                </p>
              </Table.Cell>
            </Table.Row>
            <Table.Row className="bg-blue-50 ">
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 hover:bg-white/95">
                <h3 className="italic md:text-lg  font-bold">Mr. V. V. Kadam</h3>
                <p className="italic">
                  Treasurer, Jayawanti Babu Foundation, Mumbai
                </p>
              </Table.Cell>
            </Table.Row>

            <Table.Row className="bg-blue-50 ">
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 hover:bg-white/95">
                <h3 className="italic md:text-lg font-bold">Mr. Yashwant Sawant(Advisor & Promoter)</h3>
                <p className="italic">
                Member, Advisor & Promoter, Ranjana Developers(Thane)
                </p>
              </Table.Cell>
            </Table.Row>

            <Table.Row className="bg-blue-50 ">
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 hover:bg-white/95">
                <h3 className="italic md:text-lg  font-bold">Dr. Alam Shaikh (Advisor)</h3>
                <p className="italic">
                Member, Principal,PVPP's College of Engineering,Mumbai
                </p>
              </Table.Cell>
            </Table.Row>

            <Table.Row className="bg-blue-50 ">
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 hover:bg-white/95">
                <h3 className="italic md:text-lg font-bold">Dr. Shashi Ghumre (Advisor)</h3>
                <p className="italic">
                Member,College of Engineering,Pune
                </p>
              </Table.Cell>
            </Table.Row>
            <Table.Row className="bg-blue-50 ">
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 hover:bg-white/95">
                <h3 className="italic md:text-lg  font-bold">Mr. Lan Lennox</h3>
                <p className="italic">
                Member, Director - International Affairs,MITM
                </p>
              </Table.Cell>
            </Table.Row>

            <Table.Row className="bg-blue-50 ">
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 hover:bg-white/95">
                <h3 className="italic md:text-lg font-bold">Dr.Munir Sayyad</h3>
                <p className="italic">
                Member, General Manager Jio Labs Quality Assurance,Mumbai
                </p>
              </Table.Cell>
            </Table.Row>

          </Table.Body>
        </Table>
      </div>

      {/* GOVERNMENT/AICTE/UNIVERSITY */}
      <div className="overflow-x-auto border mt-6 border-gray-200 rounded-xl">
        <Table hoverable >
          <Table.Head>
            <Table.HeadCell className="bg-blue-900 text-white text-base md:text-xl">
            GOVERNMENT/AICTE/UNIVERSITY
            </Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            <Table.Row className="bg-blue-50 ">
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 hover:bg-white/95">
                <h3 className="md:text-lg italic font-bold">Directorate of Technical Education, Nominee of DTE</h3>
                <p className="italic">
                Member
                </p>
              </Table.Cell>
            </Table.Row>
            <Table.Row className="bg-blue-50 ">
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 hover:bg-white/95">
                <h3 className="italic md:text-lg font-bold">Regional Officer, AICTE Nominee of Western Regional Office, AICTE</h3>
                <p className="italic">
                Member
                </p>
              </Table.Cell>
            </Table.Row>
            <Table.Row className="bg-blue-50 ">
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 hover:bg-white/95">
                <h3 className="italic md:text-lg font-bold">Vice-Chancellor Nominee of Mumbai University</h3>
                <p className="italic">
                Member
                </p>
              </Table.Cell>
            </Table.Row>

            <Table.Row className="bg-blue-50 ">
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 hover:bg-white/95">
                <h3 className="italic md:text-lg font-bold">Deputy Secretary Nominee of Mumbai Sub-Region, MSBTE</h3>
                <p className="italic">
                Member
                </p>
              </Table.Cell>
            </Table.Row>

          </Table.Body>
        </Table>
      </div>


      <div className="overflow-x-auto border my-6 border-gray-200 rounded-xl">
        <Table hoverable >
          <Table.Head>
            <Table.HeadCell className="bg-blue-900 text-white text-base md:text-xl">
            MEMBERS OF SECRETARY
            </Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            <Table.Row className="bg-blue-50 ">
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 hover:bg-white/95">
                <h3 className="md:text-lg italic font-bold">Dr.S.V.Dhanal</h3>
                <p className="italic">
                Members' Secretory, Principal Degree Engineering, MITM
                </p>
              </Table.Cell>
            </Table.Row>

          </Table.Body>
        </Table>
      </div>
    </motion.section>
  );
}

export default Amc;
